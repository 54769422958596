.cdo-image{
  width: 100%;
  height: auto;
}

.cdo-title{
  text-align: center;
  padding-top: 20px;
}

.cdo-text{
  max-width: 80%;
  font-size: large;
  margin: 30px auto;
  padding: 5px  0px 30px;
}

.cdo-last{
    text-align: center;
    background-color: rgba(169, 166, 161, 0.902);
    padding-top: 40px;
    padding-bottom: 100px;
}

.formatos-image{
  max-width: 100%;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stacked-image {
  width: 300px;
  height: 200px;
  margin-bottom: 20px;
}