.images-list {
  display: flex;
  flex-wrap: wrap;
}

.images-item {
  flex-basis: calc(100% / 3);
  margin: 10px auto;
  max-width: 70%;
  padding: 5px auto;
}

@media screen and (max-width: 767px) {
  .images-item {
    flex-basis: calc(100% / 2);
  }
}