.column {
  width: 33.33%;
  display: inline-block;
  vertical-align: top;
}

.card {
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 10px;
}

.card img {
  width: 100%;
  height: auto;
}
