.peticion-form {
  font-size: large;
  padding: 70px;
  margin: 45px auto;
  justify-content: center;
  background-image: url('./images/Request.png');
  background-repeat: no-repeat;
  background-size: 150%;
  text-align: center;
  margin: 10px auto;
  max-width: 800px;
}

@media (max-width: 768px) {
  .peticion-form {
    padding: 50px;
  }
}

@media (max-width: 576px) {
  .peticion-form {
    padding: 30px;
  }
}

textarea {
  width: 100%;
  height: 70px;
}

button {
  border-radius: 9px;
  width: 20%;
}

button:hover {
  background-color: lavender;
  cursor: pointer;
}