.footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  padding: 5px;
  text-align: center;
}

.footer img{
  max-width: 25px;
  margin-left: 5px;
}