.homepage{
  text-align: center;
  padding-top: 40px;
}

.main-verse{
  text-align: center;
  padding-top: 30px;
}

.homepage{
  background-image: url('/public/bible.jpg');
  background-size: cover;
  color: white;
  background-position: center;
  height: 400px
}

.last-part {
  padding-top: 40px;
  text-align: center;
  background-color: rgba(181, 161, 112, 0.902);
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.card img {
  width: 50vh;
}
