.logo {
  width: 120px;
  display: flex;
  margin: 0 auto;
}

.nav-links{
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 550px;
}

a{
  text-decoration: none;
}

nav ul {
  list-style: none;
  display: flex;
}

nav li {
  margin-right: 20px;
  position: relative;
}

nav li:hover>.dropdown {
  display: block;
}

nav .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 120px;
  padding: 10px 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  z-index: 1;
}

nav .dropdown li {
  margin: 0;
  padding: 0;
}

nav .dropdown li a {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
}

nav .dropdown li a:hover {
  background-color: #ddd;
}