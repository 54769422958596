.valores-image {
  max-width: 100%;
  height: auto;
}

.main-text {
  max-width: 80%;
  font-size: large;
  margin: 0 auto;
  padding: 30px auto;
}

